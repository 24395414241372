import TooltipInfo from '@components/TooltipInfo'
import OptionApi from '@services/OptionApi'
import appStore from '@stores/appStore'
import { FROM_MODULE_TYPE } from '@utils/constants'
import { isEmpty } from '@utils/functions'
import { Form, Select } from 'antd'
import { SizeType } from 'antd/lib/config-provider/SizeContext'
import { FormLabelAlign } from 'antd/lib/form/interface'
import cx from 'classnames'
import _ from 'lodash'
import { toJS } from 'mobx'
import { observer } from 'mobx-react'
import React, { useCallback, useContext, useState } from 'react'
import { FormContext } from '../AppForm'
import InputHint from '../InputHint'
import './style.scss'

type QueryProvider = {
  provider: (typeof OptionApi)[keyof typeof OptionApi]
  params: any
}

// const ReadonlyInputWrapper = styled(Input)`
//   input {
//     background: none;
//   }
// `
type Props = {
  className?: string
  formItemClassName?: string
  labelClassName?: string
  name?: string
  label?: JSX.Element | string
  suffixLabel?: React.ReactElement
  rules?: any[]
  required?: boolean
  labelAlign?: string
  labelCol?: any
  wrapperCol?: any
  size?: string
  disabled?: boolean
  options?: any[]
  optionDefault?: any
  value?: any
  hint?: string
  entityHint?: FROM_MODULE_TYPE
  detailTooltip?: any
  onChange?: (event, value?, option?) => void
  onPressEnter?: (e) => void
  onSearch?: (e) => void
  [x: string]: any
  textKey?: string
  valueKey?: string
  queryProvider?: QueryProvider
  optionHover?: boolean
  validateStatus?: any
  customItemDropdown?: any
  groupBy?: string
  groupLabel?: string
}

export const AppSelectInput = observer(
  ({
    className = '',
    formItemClassName = '',
    size = 'middle',
    labelAlign = 'left',
    labelClassName = '',
    labelCol, // read ant's document, please
    wrapperCol, // read ant's document, please
    label,
    suffixLabel,
    name,
    mode,
    rounded = false,
    prefixLabel = '',
    options = [],
    optionDefault = null,
    textKey = 'displayName',
    valueKey = 'id',
    value = [],
    required = false,
    allowClear = true,
    showArrow = true, // read ant's document, please
    optionFilterProp = 'children', // read ant's document, please
    hint,
    entityHint,
    onChange,
    onSearch,
    validateStatus,
    help,
    detailTooltip,
    tooltip,
    disabled = false,
    optionHover = false,
    customItemDropdown = null,
    groupBy = null,
    groupLabel = null,
    itemClassName = '',
    fieldName,
    ...props
  }: Props) => {
    const formContext = useContext(FormContext)
    const [formWidth, setFormWidth] = useState(0)

    const inputId = name ? `input-${name}` : `input-${_.uniqueId()}`
    React.useEffect(() => {
      const inputElement = document.getElementById(inputId)
      setFormWidth(inputElement?.offsetWidth ?? 0)
    }, [])

    const handleOnChange = useCallback(
      (value, option) => {
        let optionData: any = null
        if (Array.isArray(option)) {
          optionData = option.map(selectOption => {
            const item = options.find(i => i && i[valueKey] == selectOption.value)
            return item ? toJS(item) : null
          })
        } else {
          const item = options.find(i => i && i[valueKey] == option?.value)
          optionData = item ? toJS(item) : null
        }
        if (onChange) {
          if (name) {
            onChange(name, value, optionData)
          } else {
            onChange(value, optionData)
          }
        } else {
          if (name) {
            formContext.onFieldChange(name, value)
          }
        }
      },
      [formContext, name, onChange, options, valueKey],
    )

    // useEffect(() => {
    //   console.log('options changed', options)
    // }, [options])

    const [isShowingTooltip, setIsShowingTooltip] = useState(false)

    const inputRules = []

    if (required) {
      inputRules.push({ required: true, message: 'This field is required' })
    }

    const handleFilterSearch = (searchTerm, option) => {
      const originOption = options.find(i => i[valueKey] == option?.value)
      if (!originOption) {
        return false
      }

      const escapedSearchTerm = searchTerm.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
      const regex = new RegExp(escapedSearchTerm, 'i') // 'i' for case-insensitive search

      const optionLabel = getOptionLabel(originOption)
      const isMatch = regex.test(optionLabel) //1613. dev - MLP - Value List

      return isMatch
    }

    if (optionDefault) {
      if (Array.isArray(optionDefault)) {
        options = _.uniqBy<any>([...optionDefault, ...(options || [])], 'id').filter(i => i?.id)
      } else {
        options = _.uniqBy<any>([optionDefault, ...(options || [])], 'id').filter(i => i?.id)
      }
    }

    if (!(appStore.isAdminStaff || appStore.isStaff)) {
      detailTooltip = null
    }

    const getItemDropdown = opt => {
      if (customItemDropdown && typeof customItemDropdown === 'function') {
        return customItemDropdown(opt)
      }
      return (
        <>
          {!isEmpty(opt['itemIndex']) ? `ID: ${opt['itemIndex']} - ` : ''} {opt[textKey]}{' '}
          {opt.isActive === false ? ` (InActive)` : ''}
          {opt['descriptionOption'] ? (
            <span className="flex items-center">
              <TooltipInfo text={opt['descriptionOption']} />
            </span>
          ) : (
            <></>
          )}
        </>
      )
    }
    const shouldGroup = groupBy != null && groupLabel != null
    const groupedOptions: Record<string, { label: string; options: Array<any> }> | null = shouldGroup
      ? options.reduce((groups, opt) => {
          const groupKey = opt[groupBy]
          if (!groups[groupKey]) {
            groups[groupKey] = {
              label: opt[groupLabel],
              options: [],
            }
          }
          groups[groupKey].options.push(opt)
          return groups
        }, {})
      : []

    const getOptionLabel = opt => {
      let label =
        typeof opt[textKey] === 'string'
          ? `${!isEmpty(opt['itemIndex']) ? `ID: ${opt['itemIndex']} - ` : ''} ${prefixLabel + opt[textKey]}${
              opt.isActive === false ? ` (InActive)` : ''
            }`
          : opt[textKey]

      return label
    }

    const optionItem = opt => {
      let label = getOptionLabel(opt)
      return (
        <Select.Option
          value={opt[valueKey]}
          disabled={opt.disabled}
          label={
            <div className={cx(['flex', itemClassName, opt.itemClassName])}>
              {label?.length * 8 + 20 > formWidth && optionHover ? (
                <div className={cx([mode !== 'multiple' && 'max-w-[90%]'])}>
                  <TooltipInfo
                    text={label}
                    tooltipIcon={<span style={{ overflow: 'hidden', display: 'block', width: '100%' }}>{label}</span>}
                    placement="topLeft"
                  />
                </div>
              ) : (
                <div className={cx([mode !== 'multiple' && 'max-w-[90%]'])}>
                  <span style={{ overflow: 'hidden', display: 'block', width: '100%' }}>{label}</span>
                </div>
              )}
              {tooltip}
              {(detailTooltip || opt['descriptionOption']) && (
                <span className="flex items-center ml-1">
                  <TooltipInfo
                    text={
                      typeof detailTooltip === 'function'
                        ? detailTooltip(opt)
                        : detailTooltip || opt['descriptionOption']
                    }
                    onOpenChange={visible => {
                      setIsShowingTooltip(visible)
                    }}
                  />
                </span>
              )}
            </div>
          }
          className={opt.itemClassName}
        >
          {getItemDropdown(opt)}
        </Select.Option>
      )
    }

    const renderedOptions = shouldGroup
      ? Object.entries(groupedOptions)?.map(([groupKey, group]) => {
          return (
            <Select.OptGroup
              key={groupKey}
              label={<div style={{ fontSize: '16px', fontWeight: 'bold', color: '#000' }}>{group.label}</div>}
            >
              {group.options.map((opt, i) => optionItem(opt))}
            </Select.OptGroup>
          )
        })
      : options.map((opt, i) => optionItem(opt))

    return (
      <Form.Item
        name={name ?? fieldName}
        required={required}
        className={cx([formItemClassName])}
        label={
          label ? (
            <span className="w-full flex items-center gap-2">
              <span onClick={e => e.preventDefault()} className={cx([labelClassName, 'form-item-label shrink-0'])}>
                {label}
              </span>
              <span className="flex items-center">
                {suffixLabel} <InputHint text={hint} />
              </span>
            </span>
          ) : (
            ''
          )
        }
        labelAlign={labelAlign as FormLabelAlign}
        labelCol={labelCol}
        wrapperCol={wrapperCol}
        rules={inputRules}
        validateStatus={validateStatus}
        help={help}
      >
        <Select
          className={cx([className, rounded ? 'rounded-full' : '', isShowingTooltip ? 'show-tooltip' : ''])}
          size={size as SizeType}
          mode={mode}
          allowClear={allowClear}
          showArrow={showArrow}
          value={value}
          onChange={handleOnChange}
          onSearch={onSearch}
          optionLabelProp="label"
          optionFilterProp={optionFilterProp}
          showSearch
          filterOption={handleFilterSearch}
          disabled={disabled || (mode === 'multiple' && typeof detailTooltip === 'function' ? false : isShowingTooltip)}
          id={inputId}
          {...props}
        >
          {renderedOptions}
        </Select>
        {/* {disabled && (
        <ReadonlyInputWrapper
          className={cx([className, rounded ? 'rounded-full' : ''])}
          id={inputId}
          size={size as SizeType}
          type="text"
          value={selectedOptionText}
          suffix={detailTooltip ? <TooltipInfo text={detailTooltip} /> : null}
          readOnly
          style={{
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
          }}
        />
      )} */}
      </Form.Item>
    )
  },
)
