import { observer } from 'mobx-react'
import { useEffect, useMemo, useState } from 'react'
import { Spin, Upload, notification } from 'antd'
import { CloudUploadOutlined } from '@ant-design/icons'
import { ReactComponent as DownloadIcon } from 'assets/svg/download.svg'
import { ApiService } from '@services/ApiService'
import './styles.scss'
import appStore from '@stores/appStore'
import { getFileExtension } from '@utils/functions'
import { DEFAULT_FILE_TYPE } from '@utils/constants'

const { Dragger } = Upload

export const AppFileUpload = observer(
  ({
    title = 'Click or drag file to upload',
    name = 'file',
    type = DEFAULT_FILE_TYPE,
    description = '',
    multiUpload = false,
    itemRender = null,
    maxSize = 100,
    previewTemplate = null,
    fileList = [],
    attachmentTypeId = null,
    readonly = false,
    allType = null,
    onChange = (listFiles, file, formData?) => {},
    beforeUpload = file => {},
    tenantId = null,
    handleClose = () => {},
  }) => {
    const [_fileList, setFileList] = useState(fileList)
    const [imgPreview, setImgPreview] = useState<any>('')
    const [loading, setLoading] = useState<boolean>(false)
    useEffect(() => {
      if (fileList.length) {
        setFileList(fileList.map(file => ({ ...file, status: 'done' })))
      }
    }, [fileList])
    const _beforeUpload = async (file, fileList) => {
      let fileType = getFileExtension(file.name)

      beforeUpload(file)
      const match = allType ? true : type.indexOf(fileType) !== -1

      if (match) {
        setImgPreview('')
        setLoading(true)

        const size = file.size / 1024 / 1000
        if (size > maxSize) {
          notification.error({
            message: 'Error',
            description: `The file you have selected is too large. The maximum size is ${maxSize}MB.`,
          })
          setLoading(false)
          return Upload.LIST_IGNORE
        }

        let fL = [..._fileList]
        if (multiUpload) {
          if (fL.length >= maxSize) {
            notification.warning({
              message: 'Warning',
              description: `You can only upload ${maxSize} files`,
            })
            setLoading(false)
            return Upload.LIST_IGNORE
          } else {
            fL = [..._fileList, file]
          }
        } else {
          fL = [file]
          if (type.indexOf(fileType) !== -1) {
            const img = await toBase64(file)
            setImgPreview(img)
          }
        }
        await handleUpload(file, fL)
      } else {
        notification.error({
          message: 'Error',
          description: `${file.name} is not valid type '${type.join(', ')}'`,
        })
      }
      return Upload.LIST_IGNORE
    }
    let totalCount = 0

    const apiUpload = formData => {
      if (!appStore.currentUser?.id) {
        return ApiService.File.UploadAnonymousFile(formData, tenantId)
      }
      return ApiService.File.Upload(formData)
    }
    const handleUpload = async (file, fL) => {
      const formData = new FormData()

      formData.append('name', file.name)
      formData.append('file', file)
      formData.append('type', getFileExtension(file.name))

      const fl = [...fL]
      setLoading(true)

      apiUpload(formData)
        .then(({ data }) => {
          if (data.id) {
            fl[fl.length - 1] = {
              ...fl[fl.length - 1],
              ...data,
              attachmentTypeId,
              status: 'done',
              fileId: data.id,
              notSave: true,
              fileSizeInByte: file.size,
            }
            onChange([...fl], fl[fl.length - 1], formData)
            totalCount++
          } else {
            notification.error({
              message: 'Error',
              description: `${file.name} file upload failed.`,
            })
            fl[fl.length - 1].status = 'error'
          }
        })
        .catch(err => {
          notification.error({
            message: 'Error',
            description: `${file.name} file upload failed.`,
          })
          fl[fl.length - 1].status = 'error'
        })
        .finally(() => {
          setLoading(false)
          setFileList([...fl])
        })
    }

    const toBase64 = file =>
      new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
      })

    const removeFile = file => {
      appStore.openConfirmModal({
        message: 'Do you want to delete this file?',
        onOk: () => {
          const { uid } = file
          const fL = [..._fileList]
          const index = fL.findIndex(f => f.uid === uid)
          fL.splice(index, 1)
          setFileList([...fL])
          onChange([...fL], { ...file, status: 'removed' })
          if (!multiUpload) {
            setImgPreview('')
          }
          if (fL.length === 0) {
            handleClose ?? handleClose()
          }
        },
      })
    }

    const acceptTypes = useMemo(() => {
      return type.map(i => `.${i}`).join(',')
    }, [type])

    return (
      <Dragger
        className="fileupload upload-list-inline"
        accept={acceptTypes}
        beforeUpload={_beforeUpload}
        name={name}
        onDownload={file => ApiService.File.Download(file)}
        itemRender={itemRender}
        fileList={fileList}
        disabled={readonly || loading || appStore.isDemoAccount}
        showUploadList={{
          showDownloadIcon: true,
          showRemoveIcon: !readonly,
          downloadIcon: <DownloadIcon className="text-blue-400 inline" width="14" />,
        }}
        multiple={multiUpload}
        onRemove={removeFile}
      >
        {!readonly && (
          <div className="flex px-6">
            <div className="flex justify-center mr-6">
              {loading ? (
                <Spin />
              ) : (
                <div>
                  {(multiUpload || !imgPreview || (!multiUpload && !!_fileList.length)) && (
                    <CloudUploadOutlined style={{ fontSize: 50, color: 'var(--blue-400)' }} />
                  )}
                  {!multiUpload &&
                    imgPreview &&
                    !!previewTemplate &&
                    (previewTemplate || (
                      <div className="fileupload__preview">
                        <img src={imgPreview} alt="preview" />
                      </div>
                    ))}
                </div>
              )}
            </div>

            <div className="flex justify-center flex-col">
              <p className="text-left ant-upload-text text-blue-400">
                {imgPreview || !!previewTemplate || (!multiUpload && !!_fileList.length)
                  ? 'Click or drag file to replace'
                  : title}
              </p>
              <p className="text-left ant-upload-hint">{description}</p>
            </div>
          </div>
        )}
      </Dragger>
    )
  },
)
